import { api, getTracksourceReport, fetchDataSourceList, getAppsFlyerPaReport } from 'api/report';
import Pagination from '@/components/pagination';
import optionData from '@/assets/js/optionData';
import GroupSelect from '@/components/GroupSelect';
import { getReportSubruleList } from 'api/product/subrule';
import { getPidsList } from 'api/product/rankSearch';
import { getPermissionAllListNew } from 'api/account/permission';
import dateUtils from '@/utils/dateutils.js';
import { filterObject } from 'utils/utils';
import { fetchCountryList } from '@/api/DDJ/v3/country';
import qs from 'querystring';
import { mapState, mapActions } from 'vuex';
import { fetchAdxList } from 'api/DDJ/v3/dmpFilter';
import { selectCats } from 'api/product/overall';

var clickTimer = null;
export default {
  components: {
    GroupSelect,
    Pagination,
  },
  data() {
    return {
      // 筛选条件
      filter: {
        date: [],
        fromHour: '',
        toHour: '',
        sorting: 'install',
        sortType: 'desc',
        columns: [],
        ruleIds: [],
        sourceManagers: [],
        timezone: '',
        dataSourceIds: '',
        rejectionBeans: [
          {
            type: 'clickCount',
            operator: '>',
            number: 1,
          },
        ],
      },
      dataSourceFilter: false,
      dataSourceFilterForm: {
        country: '',
        os: '',
      },
      // 筛选参数状态
      filterCheckedStatus: [],
      // 列表数据
      list: [],
      // 列表数据
      palist: [],
      catOptions: [],
      // 下拉框列表
      options: {
        timezone: optionData.timezoneOption,
        offerIds: [],
        platform: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        appCategoryChecked: false,
        subRule: [],
        pidList: [],
        country: [],
        pdDomains: [],
        clickSourceApps: [],
        adxOptions: [],
        dataChecksKey: [
          { label: 'clickCount', value: 'clickCount' },
          { label: 'install', value: 'install' },
          { label: 'events', value: 'events' },
          { label: 'rejectionTotal', value: 'rejectionTotal' },
          { label: 'cvr', value: 'cvr' },
          { label: 'evr', value: 'evr' },
          { label: 'cer', value: 'cer' },
        ],
        dataCheckOperator: [
          { label: '>', value: '>' },
          { label: '<', value: '<' },
          { label: '=', value: '=' },
          { label: '>=', value: '>=' },
          { label: '<=', value: '<=' },
        ],
      },
      // 加载
      loading: {
        offerIds: false,
        list: false,
        search: false,
      },
      // 设置
      config: {
        fullWidth: document.documentElement.clientWidth,
      },
      pages: {
        total: 0,
        pageParam: {
          page: 1,
          pageSize: 20,
        },
      },
      dataSourceList: [],
      dialogVisible: false,
      pkgList: '',
      errorMessage: '',
      showPkgsFlag: false,
      catList: [],
    };
  },
  mounted() {
    //日期默认选择
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() + 3600 * 1000 * 24);
    this.filter.date = [];
    this.filter.date[0] = dateUtils.date2StrYMD(end);
    this.filter.date[1] = dateUtils.date2StrYMD(start);
    // 默认时间
    this.filter.fromHour = '00:00';
    this.filter.toHour = '23:00';
    // 默认时区
    this.filter.timezone = '+00:00';
    this.subRuleList();
    this.managerList();
    this.getPids();
    this.getCountry();
    //获取Affiliate
    this.getAffiliateList();
    //获取Source
    this.getSourceIdsList();
    // 获取dataSource数据列表
    this.getDataSourceList();
    this.getAdxList();
    this.getProdCatList();
  },
  computed: {
    ...mapState('affiliate', {
      affiliatesGroupList: (state) => state.affiliatesGroupList,
      affiliateLoading: (state) => state.affiliateLoading,
    }),
    ...mapState('source', {
      sourcesList: (state) => state.sourcesList,
      sourceLoading: (state) => state.sourceLoading,
    }),
    // 计算 InstallRejectRate
    calcInstallRejectRate: () => {
      return (insRejectTotal, installs) => {
        // 规则 如果两个参数都为0的情况 不进行计算
        if (insRejectTotal == 0 && installs == 0) {
          return 0;
        } else {
          return new Number((insRejectTotal / (insRejectTotal + installs)) * 100).toFixed(2) + '%';
        }
      };
    },
  },
  watch: {
    filterCheckedStatus: function() {
      this.$nextTick(() => {
        this.$refs.trackSourceTableRef.doLayout();
      });
    },
    'filter.columns': function(v) {
      // this.filter.sorting = v.length > 0 ? 'date' : 'rejectionTotal';
      this.filter.sorting = v.length > 0 ? 'install' : 'install';
      this.filter.sortType = 'desc';
    },
  },
  methods: {
    // get Affiliate
    ...mapActions('affiliate', ['getAffiliateList']),
    // get Source
    ...mapActions('source', ['getSourceIdsList']),
    detailClick(row) {
      if (clickTimer) {
        window.clearTimeout(clickTimer);
        clickTimer = null;
      }
      var that = this;
      clickTimer = window.setTimeout(function() {
        that.$nextTick(() => {
          const { href } = that.$router.resolve({
            path: '/offer/detail',
            query: {
              offerId: row.offerId,
              country: row.countries,
              os: row.platforms,
            },
          });
          window.open(href, '_blank');
        });
      }, 300);
    },
    managerList() {
      getPermissionAllListNew().then((res) => {
        this.options.sourceManagers = res.result;
      });
    },
    selectAll(val) {
      const arr = this.catOptions;
      if (val) {
        arr.forEach((item) => {
          if (!this.filter.appCategoryList.includes(item.cat)) {
            this.filter.appCategoryList.push(item.cat);
          }
        });
      } else {
        this.filter.appCategoryList = [];
      }
      console.log('catList:');
      console.log(this.catOptions);
      console.log('appCategoryList:');
      console.log(this.filter.appCategoryList);
    },

    dataFilter(query) {
      // this.value = val //给绑定值赋值
      console.log('query:' + query);
      console.log(query);
      if (query == '' || query == null) {
        //val不存在还原数组
        this.catOptions = this.catList;
      } else {
        let result = []; //存储符合条件的下拉选项
        this.catList.forEach((val) => {
          if (val.cat && val.cat.indexOf(query) != -1) result.push(val);
        });
        this.catOptions = result;
      }
    },
    handleChange(val) {
      if (this.filter.appCategoryList == null || this.filter.appCategoryList.length === 0) {
        this.appCategoryChecked = false;
        this.catOptions = this.catList;
      }
      this.$emit('change', val);
    },
    //查询adx列表
    getAdxList() {
      fetchAdxList().then((res) => {
        this.options.adxOptions = res.result;
      });
    },
    // 国家
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    getPids() {
      getPidsList().then((res) => {
        this.options.pidList = res.result;
      });
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex > 0 && rowIndex === this.list.length - 1) {
        return 'bgdfdfdf';
      }
    },
    // 拼接filter数据
    filterDataSet: function(e, page) {
      let param = {
        fromDate: this.filter.date[0],
        toDate: this.filter.date[1],
      };
      // 重置翻页
      if (page && page !== '') {
        this.pages.pageParam.page = page;
      }
      if (this.filter.fromHour && this.filter.toHour) {
        param.fromHour = this.filter.fromHour.substring(0, 2);
        param.toHour = this.filter.toHour.substring(0, 2);
      } else {
        this.$message.error('Hour 选项不能为空！');
        return;
      }
      if (this.filter.timezone) {
        param.timezone = this.filter.timezone;
      }
      if (this.filter.offerIds) {
        param.offerIds = this.filter.offerIds;
      }
      if (this.filter.affiliateIds) {
        param.affiliateIds = this.filter.affiliateIds.toString();
      }
      if (this.filter.sourceIds) {
        param.sourceIds = this.filter.sourceIds.toString();
      }
      if (this.filter.countries) {
        param.countries = this.filter.countries;
      }
      if (this.filter.pkgs) {
        param.pkgs = this.filter.pkgs;
      }
      if (this.filter.appCategoryList) {
        param.appCategoryList = this.filter.appCategoryList.toString();
      }
      if (this.filter.platform) {
        param.platform = this.filter.platform.toString();
      }
      if (this.filter.affSubs) {
        param.affSubs = this.filter.affSubs;
      }
      if (this.filter.sorting) {
        param.sorting = this.filter.sorting;
      }
      if (this.filter.sortType) {
        param.sortType = this.filter.sortType;
      }
      if (this.filter.ruleIds) {
        param.ruleIds = this.filter.ruleIds;
      }
      if (this.filter.sourceManagers.length > 0) {
        param.sourceManagers = this.filter.sourceManagers.toString();
      }
      if (this.filter.pids?.length > 0) {
        param.pids = this.filter.pids.toString();
      }
      if (this.filter.offerTypes?.length > 0) {
        param.offerTypes = this.filter.offerTypes.toString();
      }
      if (this.filter.dataSourceIds?.length > 0) {
        param.dataSourceIds = this.filter.dataSourceIds.toString();
      }

      if (this.filter.pdDomains) {
        param.pdDomains = this.filter.pdDomains;
      }

      if (this.filter.clickSourceApps) {
        param.clickSourceApps = this.filter.clickSourceApps;
      }

      if (this.filter.trafficAppTagList?.length > 0) {
        param.trafficAppTagList = this.filter.trafficAppTagList.toString();
      }

      if (this.filter.adxs?.length > 0) {
        param.adxs = this.filter.adxs.toString();
      }

      if (this.filter.osVersion) {
        param.osVersion = this.filter.osVersion;
      }
      if (this.filter.appCategory) {
        param.appCategory = this.filter.appCategory;
      }

      param.columns = this.filter.columns.concat(this.filterCheckedStatus).toString();
      if (param.columns.indexOf('affiliate_id') > -1) {
        param.columns = param.columns + ',company';
      }
      /* if (param.columns.indexOf('click_source_app') > -1) {
        param.columns = param.columns ;
      }*/
      if (this.showPkgsFlag && this.filter.rejectionBeans) {
        param.filterDataListStr = JSON.stringify(this.filter.rejectionBeans);
      }
      // param.page = this.pages.pageParam.page;
      // param.pageSize = this.pages.pageParam.pageSize;
      if (e === 'Search') {
        this.searchListClick(param);
      }
      if (e === 'Download') {
        this.exportExcelFileClick(param);
      }
    },
    // 筛选
    searchListClick(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getTracksourceReport(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success === true) {
            let result = res.result || [];
            if (result.length === 0) {
              this.list = [];
              return;
            }
            this.list = [];
            result[result.length - 1].date = 'total';

            this.pkgList = result[result.length - 1].prods;
            this.list = result;
            this.pages.total = res.total;
          } else {
            this.$message.error(res.message);
            this.list = [];
          }
        })
        .catch((error) => {
          // console.log(error);
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    searchPaRepoertList(param) {
      let par = {
        ...this.pages.pageParam,
        ...param,
      };
      this.loading.list = true;
      this.loading.search = true;
      getAppsFlyerPaReport(par)
        .then((res) => {
          this.loading.list = false;
          this.loading.search = false;
          if (res.code === 200 && res.success === true) {
            let result = res.result || [];
            if (result.length === 0) {
              this.palist = [];
              return;
            }
            this.palist = [];
            this.palist = result;
            this.pages.total = res.total;
          } else {
            this.$message.error(res.message);
            this.palist = [];
          }
        })
        .catch((error) => {
          this.$message.error('查询失败！' + error.toString());
          this.loading.list = false;
          this.loading.search = false;
        });
    },
    // 导出
    exportExcelFileClick(param) {
      if (param) {
        param = filterObject(param);
        let strParam = qs.stringify(param);
        window.open(`${api.DOWNLOAD_TRACKSOURCE_REPORT}?${strParam}`, '_blank');
      }
    },
    // 更改每页显示数
    handleSizeChange(size) {
      // console.log(size);
      this.pages.pageParam.pageSize = size;
      this.handleCurrentChange(1); // 更改每页显示数以后页码重置为0
    },
    // 点击数字翻页
    handleCurrentChange(currentPage) {
      this.pages.pageParam.page = currentPage;
      this.filterDataSet('Search');
    },
    // 排序监听
    sortChange(column) {
      // console.log(column);
      if (column.prop !== null && column.order !== null) {
        this.filter.sorting = column.prop;
        this.filter.sortType = column.order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.filter.sorting = '';
        this.filter.sortType = '';
      }
      this.filterDataSet('Search');
    },
    // sub rule
    subRuleList() {
      getReportSubruleList().then((res) => {
        // console.log(res);
        this.options.subRule = res.result;
      });
    },
    checkIn(column) {
      let checked = false;
      this.filterCheckedStatus.forEach((item) => {
        if (item === column) {
          checked = true;
        }
      });
      return checked;
    },
    checkDate(column) {
      return this.filter.columns.indexOf(column) > -1;
    },

    getDataSourceList(data) {
      fetchDataSourceList(data).then((res) => {
        var dataSource = [];
        for (let i in res.result) {
          dataSource.push({ data_source_id: i, data_source_name: res.result[i] });
        }
        this.dataSourceList = dataSource;
        if (this.dataSourceFilter === true) {
          this.dataSourceFilter = false;
          this.$message.success('data Source 筛选成功');
        }
      });
    },
    dataSourceFilterSubmit() {
      this.getDataSourceList(this.dataSourceFilterForm);
    },
    // 添加 data check(and) item
    dataCheckAdd() {
      const dataCheckObj = {
        type: '',
        operator: '',
        number: '',
      };
      this.filter.rejectionBeans.push(dataCheckObj);
      console.log(this.filter.rejectionBeans);
    },
    // 删除 data check(and) item
    dataCheckDel(index) {
      this.filter.rejectionBeans.splice(index, 1);
    },
    computeRate(num1, num2) {
      if (num2 === 0) {
        return '0%'; // 处理除以零的情况
      }
      const rate = (num1 / num2) * 100; // 计算比值并转换为百分比
      return `${rate.toFixed(6)}%`; // 返回格式化的百分比
    },
    async copyToClipboard() {
      try {
        console.log('copyToClipboard');
        // await navigator.clipboard.writeText(this.pkgList); // 使用 Clipboard API 复制文本

        const inputElement = document.querySelector('#selectPkgList');
        inputElement.select();
        document.execCommand('copy');
        this.dialogVisible = false;
      } catch (err) {
        this.$message.error('复制失败!'); // 设置失败提示
      }
    },
    validateInput(num) {
      const regex = /^\d+(\.\d+)?$/; // 正则表达式，匹配 BigDecimal 格式
      if (!regex.test(num)) {
        this.errorMessage = '请输入有效的数字格式'; // 错误提示
      } else {
        this.errorMessage = ''; // 清除错误提示
      }
    },
    getProdCatList() {
      selectCats({})
        .then((res) => {
          if (res.code === 200) {
            this.catList = res.result;
            this.catOptions = this.catList;
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((e) => {
          this.$message.error(e);
        });
    },
    openShowPkgsFlag() {
      this.showPkgsFlag = true;
    },
  },
};
